import { Card, CardContent, CardHeader } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { DocumentDetails } from '../../models/DocumentType';
import { TermState } from '../../models/TermState';
import { getSettings } from '../../services/Settings';
import AgreementTerms from './AgreementTerms';
import TermsOfService from './TermsOfService';

interface Props {
  documents: DocumentDetails[];
  country?: string;
  toggleTermsOfService: () => void;
  terms?: TermState[];
  cToken?: string;
  onTermsChanged?: (mapping: string, value: boolean) => void;
}

export default class AdditionalDocuments extends React.Component<Props> {
  private termsLink;

  public render() {
    const {
      documents, country, toggleTermsOfService, terms, onTermsChanged,
    } = this.props;

    let termsOfService;
    let marketingAgreement;
    let titleHeader = <Trans i18nKey="additional_documentation" />;
    const links = _.map(documents, (document, i) => {
      if (document.titleKey === 'partner_documents' || document.titleKey === 'partner_static_documents') {
        return (
          <div key={`documentLink${i}`}>
            <a href={this.getDocumentLinkWithAuthParam(document)} target="_blank" rel="noreferrer" role="button">
              {document.partnerDocName}
            </a>
          </div>
        );
      }
      return (
        <div key={`documentLink${i}`}>
          <a href={this.getDocumentLinkWithAuthParam(document)} target="_blank" rel="noreferrer" role="button">
            <Trans i18nKey={document.titleKey} />
          </a>
        </div>
      );
    });
    if (this.shouldShowTermsOfService(country)) {
      termsOfService = (
        <TermsOfService toggleTermsOfService={toggleTermsOfService} termsLink={this.termsLink} />
      );
      titleHeader = <Trans i18nKey="terms_of_service" />;
    }
    if (this.shouldShowMarketingAgreement(country)) {
      if (terms && onTermsChanged) {
        marketingAgreement = (
          <AgreementTerms terms={terms} onTermsChanged={onTermsChanged} />
        );
      }
    }
    return (
      <Card>
        <CardHeader title={titleHeader} />
        <CardContent>
          {termsOfService}
          {marketingAgreement}
          <p><Trans>document_list_label</Trans></p>
          {links}
        </CardContent>
      </Card>
    );
  }

  private getDocumentLinkWithAuthParam(document) {
    const settings = getSettings();
    const jwtToken = window.sessionStorage.getItem(settings.tokenKey);
    const noCToken = ['terms_of_service', 'terms_of_service_english', 'terms_of_service_french', 'merchant_operating_guide',
      'merchant_operating_guide_english', 'merchant_operating_guide_french', 'opayo_terms_of_service'];
    if (_.includes(noCToken, document.titleKey)) {
      if (document.link.includes('getdocumentpdf') || document.link.includes('scwebapi/apps/getdoc')) {
        if (this.props.cToken === null || this.props.cToken === undefined) {
          return `${document.link}&j=${jwtToken}`;
        }
        return `${document.link}&ctoken=${this.props.cToken}`;
      }
      return `${document.link}`;
    }

    if (this.props.cToken === null || this.props.cToken === undefined) {
      return `${document.link}&j=${jwtToken}`;
    }
    return `${document.link}&ctoken=${this.props.cToken}`;
  }

  private shouldShowTermsOfService(country: string | undefined): boolean {
    const whitelist = ['NOR'];
    return _.includes(whitelist, country);
  }

  private shouldShowMarketingAgreement(country: string | undefined): boolean {
    const whitelist = ['NOR', 'POL', 'GBR', 'IRL', 'SWE', 'DEU', 'DNK', 'FIN'];
    return _.includes(whitelist, country);
  }
}
