import i18next from 'i18next';
import { of } from 'rxjs';
import { TermState } from '../models/TermState';

export function getAgreements(
  country: string,
  ticked: boolean,
  isMarketingDataConsent2Required?: boolean|undefined,
  isMarketingDataConsent2Disabled?: boolean|undefined,
) {
  const gdprOptInOut = {
    required: false,
    disabled: false,
    checked: ticked,
    header: i18next.t('marketing_header'),
    label: [i18next.t('marketing_body'), i18next.t('marketing_checkbox'), i18next.t('marketing_additional')],
    mapping: 'gdprOptInOut',
  };

  const gdprMarketingOptOut = {
    required: false,
    disabled: false,
    checked: !ticked,
    header: i18next.t('marketing_header'),
    label: [i18next.t('marketing_opt_out_body'), i18next.t('marketing_opt_out_checkbox')],
    mapping: 'gdprOptInOut',
  };

  const hiddenOptOut = {
    required: false,
    disabled: false,
    checked: ticked,
    header: '',
    label: ['', '', ''],
    mapping: 'gdprOptInOut',
  };

  const hiddenTermState: TermState[] = [
    hiddenOptOut,
  ];

  const norwayTermStates: TermState[] = [
    gdprOptInOut,
  ];

  const polishTermStates: TermState[] = [
    {
      required: true,
      disabled: false,
      checked: false,
      header: `Kliknij tutaj aby wyrazić Twoją zgodę na
            udostępnienie danych o umowie oraz kwestiach
             PCI-DSS oraz Twoją zgodę na działania Elavon
             w celu wypełnienia obowiązków związanych z
             przepisami `,
      label: [
        `dotyczącymi przeciwdziałaniu prania brudnych pieniędzy i finansowania
             terroryzmu (AML)
            Wyrażam zgodę na przekazywanie przez Elavon informacji
             stanowiących Tajemnicę Bankową do Podmiotów Stowarzyszonych
            Elavon należących do grupy kapitałowej U.S. Bancorp oraz
            do Organizacji Kart Płatniczych, w tym znajdujących się
             poza Europejskim Obszarem Gospodarczym. Przyjmuję też do
            wiadomości, że Elavon jest zobowiązany do wypełnienia
            obowiązków związanych z przeciwdziałaniem praniu
            brudnych pieniędzy i finansowaniu terroryzmu i w
            tym również celu są zbierane, przekazywane i
             analizowane poufne informacje. W zakresie,
            w jakim sa lub maja byc swiadczone usługi
             zwiazane z PCI, dane poufne moga zostac
            przekazane do współpracujacych z Elavon
             podmiotów trzecich w szczególnosci do Sysxnet
             Limited (spółki działajacej pod nazwa handlowa
            SYSNET Global Solutions), Trustwave oraz ich
             podmiotów powiazanych.
            (UDZIELENIE TEJ ZGODY JEST NIEZBĘDNE W CELU PRAWIDŁOWEGO
             ŚWIADCZENIA PRZEZ NAS USŁUG).
            `],
      mapping: 'marketingDataConsent1',
    },
    {
      required: isMarketingDataConsent2Required ?? true,
      disabled: isMarketingDataConsent2Disabled ?? false,
      checked: false,
      header: `Dla działalności jednoosobowej – kliknij tutaj aby wyrazić
                 zgodę na zbieranie i udostępniania Twoich danych osobowych`,
      label: [`
                Na podstawie art. 105 ust. 4a1 Prawa Bankowego oraz art. 24 ust. 1
                w związku z art. 64 ustawy z dnia 9 kwietnia 2010 r. o udostępnianiu
                informacji gospodarczych i wymianie danych gospodarczych (t.j. Dz. U. z 2014
                 r. poz. 1015 z pózn. zm.)(UDZIELENIE TEJ ZGODY JEST WYMAGANE, JEŻELI AKCEPTANT
                JEST JEDNOOSOBOWYM PRZEDSIĘBIORCĄ W TYM, GDY UMOWA JEST ZAWIERANA PRZEZ
                 WSPÓLNIKÓW SPÓŁKI CYWILNEJ).(„Ustawa o Udostępnianiu
                Informacji Gospodarczych”)upoważniam Elavon do wystąpienia do biur
                 informacji gospodarczych, działających na podstawie tej ustawy, o
                ujawnienie informacji gospodarczych o zobowiązaniach osobistych
                 zaciągniętych przeze mnie działającego jako konsumenta oraz informacji
                stanowiących Tajemnice Bankowa dotyczących moich zobowiązań
                 względem banków oraz instytucji ustawowo upoważnionych do
                 udzielania kredytów, a także zobowiązaniach powstałych
                 przed wejściem w życie tej ustawy. Jednocześnie upoważniam
                biura informacji gospodarczej działające na podstawie
                 Ustawy o Udostępnianiu Informacji Gospodarczych do
                 ujawnienia Elavon informacji gospodarczych, o których
                 mowa w zdaniu poprzedzającym, w tym informacji o
                 zobowiązaniach powstałych przed wejściem w życie tej
                 ustawy oraz informacji stanowiących Tajemnice Bankowa.
                (UDZIELENIE TEJ ZGODY JEST WYMAGANE, JEZELI AKCEPTANT JEST
                JEDNOOSOBOWYM PRZEDSIEBIORCA W TYM, GDY UMOWA JEST
                 ZAWIERANA PRZEZ WSPÓLNIKÓW SPÓŁKI CYWILNEJ)
                `],
      mapping: 'marketingDataConsent2',
    },
    gdprMarketingOptOut,
  ];

  const britishTermStates: TermState[] = [
    gdprOptInOut,
  ];

  const irishTermStates: TermState[] = [
    gdprOptInOut,
  ];

  const swedishTermStates: TermState[] = [
    gdprOptInOut,
  ];

  const germanTermStates: TermState[] = [
    gdprOptInOut,
  ];

  const danishTermStates: TermState[] = [
    gdprOptInOut,
  ];

  const finnishTermStates: TermState[] = [
    gdprOptInOut,
  ];

  const agreementsMap = {
    POL: polishTermStates,
    NOR: norwayTermStates,
    GBR: britishTermStates,
    IRL: irishTermStates,
    SWE: swedishTermStates,
    DEU: germanTermStates,
    DNK: danishTermStates,
    FIN: finnishTermStates,
    '-': hiddenTermState,
  };

  if (agreementsMap[country]) {
    return of(agreementsMap[country]);
  }
  return of([]);

  // TODO: add this call later when we check boxes on the back end
  // return ARHttp({
  //     method: "POST",
  //     url: "/sb/documents",
  //     body: getAgreementsRequest,
  // }).pipe(
  //     map((response) => {
  //         return response;
  //     }),
  //     catchError ((error) => {
  //         if (country === "POL") {
  //             return of(polishTermStates);
  //         } else {
  //             return of(error);
  //         }
  //     }),
  // );
}
